import * as React from 'react'
import { Component } from 'react';
import './home.scss'

class Home extends Component {
  
  render = () => {
    return (
      <div className="text-wrapper">
        <div className="text">Empowering Business Through Strategic <br></br> Next Generation Technology Solutions</div>
      </div>
    );
  }
}

export default Home;
