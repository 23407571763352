import React, { Component } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { MenuComponent } from './components/Menu/Menu';
import { Home } from "./pages/Home";
import { Contact } from "./pages/Contact";
import { About } from "./pages/About";
import { NotFound }from "./pages/NotFound";

class App extends Component {
  render() {
      const App = {
          backgroundImage: "url(/bkg.jpg)",
          height: "100vh",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
      };
      return (
        <div style={App} className="App">
          <div className="menu-logo">
            <a href="/">
              <div className="logo-top">jansma</div>
              <div className="logo-bottom">technology</div>
            </a>
          </div>
          <MenuComponent />
            <BrowserRouter>
              <Routes>
                <Route path="/">
                  <Route index element={<Home />} />
                  <Route path="about" element={<About />}/>
                  <Route path="contact" element={<Contact />} />
                  <Route path="*" element={<NotFound />} />
                </Route>
              </Routes>
            </BrowserRouter> 
          {/* <div className="ripple-background">
            <div className="circle xxlarge shade1"></div>
            <div className="circle xlarge shade2"></div>
            <div className="circle large shade3"></div>
            <div className="circle mediun shade4"></div>
            <div className="circle small shade5"></div>
          </div> */}
        </div>
      );
  }
}


export default App;