import * as React from 'react'
import { Component } from 'react';
import './about.scss'

class Contact extends Component {

  render = () => {
    return <div className="about text-wrapper">
        <div className="text">
          <div className="about-header">about</div>
          <div>We are a diverse, woman owned technology company specializing in technology services for private and public sector clients.</div>
        </div>
    </div>;
  };
}

export default Contact;