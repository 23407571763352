import * as React from 'react'
import { Component } from 'react';
import './contact.scss'

class Contact extends Component {

  render = () => {
    return <div className="contact text-wrapper">
        <div className="contact-text">
          <div className="contact-header">contact us</div>
          <a href="mailto: contact@jansmatech.com">contact@jansmatech.com</a>
        </div>
    </div>;
  };
}

export default Contact;
